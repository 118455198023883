



























import { Component, Vue, Prop } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'
import { snackController } from './snack-bar/snack-bar-controller'

@Observer
@Component
export default class extends Vue {
  @Prop({ default: 'TBA' }) address
  @Prop({ default: false }) alignRight
  @Prop({ default: false }) forceHide
  @Prop({ default: 14 }) iconSize
  @Prop({ default: '' }) iconColor
  @Prop({ default: false }) isShortAddress
  @Prop({ default: 6 }) shortStartAmount
  @Prop({ default: 10 }) shortEndAmount
  isVisibleCopy = false
  copyAddress() {
    navigator.clipboard.writeText(this.address)
    snackController.success('Copied wallet address to clipboard successfully!')
  }
  onMouseEnter() {
    this.isVisibleCopy = true
  }
  onMouseLeave() {
    this.isVisibleCopy = false
  }
}
